<template>
  <PdfPart title="Signatures">
    <div
      class="flex flex-row justify-center items-center pt-4 gap-10"
      v-if="customerSignature">
      <div class="flex flex-col">
        <strong>Signature client :</strong>
        <span class="text-gray02">le {{ $moment(checkin.customer_inspection_completed_at).format("DD MMM YYYY à HH:mm:ss") }}</span>
      </div>
      <img
        class="h-40"
        crossorigin="*"
        :src="customerSignature.url"
        alt="signature">
    </div>
    <div
      class="flex flex-row justify-center items-center pt-4 gap-10"
      v-if="receptionistSignature">
      <div class="flex flex-col">
        <strong>Signature conseiller client :</strong>
        <span class="text-gray02">le {{ $moment(checkin.receptionist_inspection_completed_at).format("DD MMM YYYY à HH:mm:ss") }}</span>
      </div>
      <img
        class="h-40"
        crossorigin="*"
        :src="receptionistSignature.url"
        alt="signature">
    </div>
  </PdfPart>
</template>

<script>
import PdfPart from "@/components/summary/ui/PdfPart"

export default {
  name: "Signature",
  components: { PdfPart },
  props: {
    pictures: {
      type: Object,
      required: true
    },
    checkin: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    customerSignature() {
      return this.pictures.filter(picture => picture.type == "customer_signature")[0] || null
    },
    receptionistSignature() {
      return this.pictures.filter(picture => picture.type == "receptionist_signature")[0] || null
    }
  }
}
</script>
