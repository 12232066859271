import StringService from "@/services/StringService.js"

export default function useMask(type) {
  switch (type) {
    case "lastname":
      return _getLastnameMask()
    case "business_name":
      return _getBusinessNameMask()
    case "firstname":
      return _getFirstnameMask()
    case "phone":
      return _getPhoneMask()
    case "licence":
      return _getLicenceMask()
    case "date":
      return _getDateMask()
    case "plate_number":
      return _getPlateNumberMask()
    case "number":
      return _getNumberMask()
    default:
      return null
  }

}

function _getLastnameMask() {
  return {
    mask: "A",
    tokens: {
      "A": {
        pattern: /[-\\a-zàâæçéèêëïîôœùûüÿA-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ ']/,
        transform: (chr) => chr.toUpperCase(),
        multiple: true
      }
    }
  }
}

function _getFirstnameMask() {
  return {
    mask: "A",
    postProcess: (value) => StringService.title(value),
    tokens: {
      "A": {
        pattern: /[-\\a-zàâæçéèêëïîôœùûüÿA-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ ']/,
        multiple: true,
      }
    }
  }
}

function _getPhoneMask() {
  return {
    mask: "+33 0 ## ## ## ##",
    tokens: { "0": { pattern: /[1-9]/ } }
  }
}

function _getLicenceMask() {
  return { 
    mask: new Array(16).join("A"),
    tokens: {
      "A": {
        pattern: /[a-zA-Z0-9]/,
        transform: (chr) => chr.toUpperCase() 
      }
    }
  }
}

function _getBusinessNameMask() {
  return {
    mask: "A",
    tokens: {
      "A": {
        transform: (chr) => chr.toUpperCase(),
        multiple: true
      }
    }
  }
}

function _getDateMask() {
  return {
    mask: "00/00/0000",
    tokens: { "0": { pattern: /[0-9]/ } }
  }
}

function _getPlateNumberMask() {
  let oldPlateMask = "0000 AA 00"
  let newPlateMask = "AA 000 AA"

  return {
    mask: [newPlateMask, oldPlateMask],
    tokens: {
      "A": { 
        pattern: /[a-zA-Z]/, 
        transform: (chr) => chr.toUpperCase(), 
      },
      "0": { pattern: /[0-9]/ }
    }
  }
}

function _getNumberMask() {
  return {
    mask: "0 00#",
    reversed: true,
    tokens: {
      0: {
        pattern: /[0-9]/,
        repeated: true,
      } 
    }
  }
}