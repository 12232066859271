<template>
  <img
    class="w-full max-w-xs max-h-16 object-contain"
    :src="workspace.theme.picture_url ? workspace.theme.picture_url : mpaLogo"
    alt="logo">
</template>

<script>
import mpaLogo from "@/assets/img/banner-simplycheck.png"

export default {
  name: "WorkspaceLogo",
  data() {
    return { mpaLogo }
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  }
}
</script>
