<template>
  <div class="flex justify-between flex-col sm:flex-row gap-4 text-sm sm:text-base">
    <div class="flex flex-col">
      <span class="font-bold uppercase">
        {{ workspace.commercial_name}}
      </span>
      <span>
        {{ workspace.full_address }}
      </span>
      <span>
        {{ workspace.postal_code }}
      </span>
      <span>
        {{ workspace.city }}
      </span>
      <span>
        {{ workspace.phone_number }}
      </span>
    </div>
    <div class="flex gap-2 text-right justify-end">
      <div class="flex flex-col">
        <span>
          État des lieux d'entrée
        </span>
        <span>
          EXEMPLAIRE CLIENT
        </span>
      </div>
      <div class="flex justify-center items-center border border-plainBlack w-32 h-32 p-2">
        <img
          class="object-contain"
          :src="workspace.theme.picture_url"
          alt="logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
}
</script>
