<template>
  <div class="h-full w-full flex flex-col absolute top-0 left-0 bottom-0 right-0 gap-4">
    <div class="h-full flex flex-col justify-center gap-4 overflow-hidden">
      <div
        v-if="props.item.label || props.item.description"
        class="flex flex-col gap-1">
        <span
          class="font-bold text-gray01"
          v-if="props.item.label"><span v-if="props.item.index != 0">{{ props.item.index }} - </span>{{ props.item.label }}</span>
        <span
          class="text-gray03 text-sm"
          v-if="props.item.description">{{ props.item.description }}</span>
      </div>
      <div class="overflow-y-scroll py-2 no-scrollbar">
        <slot />
      </div>
    </div>
    <div class="shrink-0">
      <FormActions
        v-if="hasActions"
        @next="handleNext"
        @previous="$emit('previous')"
        :item="item"
        :is-loading="item.isLoading"
        :is-disabled="!canGoNext" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue"
import FormActions from "@/components/ui/form/FormActions.vue"
import useValidator from "@/composables/useValidator.js"

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  currentValue: {
    type: [String, Array, Boolean, Object],
    default: null
  }
})

const emit = defineEmits(["next", "previous"])

const canGoNext = computed(() => useValidator(props.currentValue, props.item.validators))
const hasActions = computed(() => !props.item.actions || props.item.actions.type !== "hidden")

function handleNext() {
  emit("next", props.currentValue)
}
</script>