// https://materialdesignicons.com/
/* eslint-disable*/
import AlertCircle from "vue-material-design-icons/AlertCircle.vue"
import ArrowURightBottom from "vue-material-design-icons/ArrowURightBottom.vue"
import Camera from "vue-material-design-icons/Camera.vue"
import Car from "vue-material-design-icons/Car.vue"
import CarSide from "vue-material-design-icons/CarSide.vue"
import Check from "vue-material-design-icons/Check.vue"
import CheckboxBlankCircle from "vue-material-design-icons/CheckboxBlankCircle.vue"
import CheckboxBlankCircleOutline from "vue-material-design-icons/CheckboxBlankCircleOutline.vue"
import CheckboxBlankOutline from "vue-material-design-icons/CheckboxBlankOutline.vue"
import CheckboxOutline from "vue-material-design-icons/CheckboxOutline.vue"
import ChevronRight from "vue-material-design-icons/ChevronRight.vue"
import Close from "vue-material-design-icons/Close.vue"
import ClockTimeFourOutline from "vue-material-design-icons/ClockTimeFourOutline.vue"
import Counter from "vue-material-design-icons/Counter.vue"
import Delete from "vue-material-design-icons/Delete.vue"
import Loading from "vue-material-design-icons/Loading.vue"
import RadioBlank from "vue-material-design-icons/RadioboxBlank.vue"
import RadioMarked from "vue-material-design-icons/RadioboxMarked.vue"
import Plus from "vue-material-design-icons/Plus.vue"

export default {
  install: (app, options) => {
    app.component("alert-circle", AlertCircle)
    app.component("arrow-u-right-bottom", ArrowURightBottom)
    app.component("camera", Camera)
    app.component("car", Car)
    app.component("car-side", CarSide)
    app.component("check", Check)
    app.component("checkbox-blank-circle", CheckboxBlankCircle)
    app.component("checkbox-blank-circle-outline", CheckboxBlankCircleOutline)
    app.component("checkbox-blank-outline", CheckboxBlankOutline)
    app.component("checkbox-outline", CheckboxOutline)
    app.component("chevron-right", ChevronRight)
    app.component("close", Close)
    app.component("clock", ClockTimeFourOutline)
    app.component("delete", Delete)
    app.component("counter", Counter)
    app.component("loading", Loading)
    app.component("plus", Plus)
    app.component("radio-blank", RadioBlank)
    app.component("radio-marked", RadioMarked)
  }
}
