<template>
  <ServiceLayout
    ref="layout"
    :label="service.label"
    :subtitle="subtitle"
    :selected-option="selectedOptionLabel">
    <div class="flex flex-col gap-2">
      <div
        class="flex px-4 h-[50px] bg-paperWhite rounded-lg items-center justify-between cursor-pointer text-sm"
        v-for="(value, key) in options"
        :key="key"
        @click.stop="setServiceAction(key)">
        <span class="flex-1 truncate">{{ value }}</span>
        <check
          class="text-mandarineOrange w-5"
          v-if="selectedOption === key"/>
      </div>
    </div>
  </ServiceLayout>
</template>

<script>
import ServiceLayout from "@/components/ui/form/services/ServiceLayout"

export default {
  name: "ServiceItem",
  components: { ServiceLayout },
  props: {
    service: {
      type: Object,
      required: true
    },
    selectedServices: {
      type: Object,
      required: true
    } 
  },
  data() {
    return {
      options: {
        unactive: "Non",
        replacement: "Executer",
        inspection: "Contrôler"
      }
    }
  },
  computed: {
    subtitle() {
      return this.service.price ? `À partir de ${this.service.price}€` : null
    },
    selectedOption() {
      let service = this.selectedServices.find(selectedService => selectedService.id === this.service.id)
      return service ? service.action : "unactive"
    },
    selectedOptionLabel() {
      return this.selectedOption !== "unactive" ? this.$t(`services.${this.selectedOption}`) : null
    }
  },
  methods: {
    setServiceAction(action) {
      if (action === this.selectedOption) {
        return
      }
      
      if (action == "unactive") {
        this.handleUnselect()
        return
      }
      
      this.handleSelect(action)
    },

    handleUnselect() {
      let additionalServices = JSON.parse(JSON.stringify(this.selectedServices))
      let serviceIndex = additionalServices.findIndex(service => service.id == this.service.id)
      additionalServices.splice(serviceIndex, 1)

      this.$emit("update", additionalServices)
      this.handleClose()
    },
  
    handleSelect(option) {
      let additionalServices = JSON.parse(JSON.stringify(this.selectedServices))

      let service = additionalServices.find(service => service.id === this.service.id)
      if (!service) {
        service = {
          id: this.service.id,
          type: this.service.type
        }
        additionalServices.push(service)
      }

      service.action = option

      this.$emit("update", additionalServices)
      this.handleClose()
    },

    handleClose() {
      this.$refs.layout.handleClose()
    }
  }
}
</script>