<template>
  <div class="flex flex-col gap-10 items-center justify-between shrink-0">
    <div class="w-full flex items-center gap-4 p-4 border border-paperWhite rounded-lg shadow-md">
      <img
        class="h-12 object-contain rounded-full"
        :src="Nathalie"
        alt="nathalie">
      <div class="flex flex-col gap-1 text-sm">
        <span class="text-gray01 font-medium">
          Assistante virtuelle de Nathalie
        </span>
        <span class="text-gray03">
          pour <span class="text-gray01 font-medium">{{ props.workspace.commercial_name }}</span>
        </span>
      </div>
    </div>
    <img
      class="h-14 max-w-full object-contain"
      :src="logo"
      alt="logo">
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import Nathalie from "@/assets/img/nathalie-thumbnail.png"
import simplyCheckLogo from "@/assets/img/banner-simplycheck.png"

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  }
})

const logo = computed(() => {
  let workspaceLogo = props.workspace.theme.picture_url || null
  return workspaceLogo ? workspaceLogo : simplyCheckLogo
})
</script>