<template>
  <div class="h-full flex flex-shrink-0 justify-center bg-paperWhite">
    <div class="max-w-[640px] flex-1 h-full shadow-lg">
      <div v-if="isLoading">
        <CarLoader />
      </div>
      <div
        v-else-if="isError"
        class="flex flex-col items-center justify-center h-full">
        <span>Oups...</span>
        <span>Une erreur est survenue, veuillez recharger la page !</span>
      </div>
      <div
        class="flex flex-col h-full p-5 gap-5 bg-plainWhite overflow-hidden"
        v-else>
        <Header :workspace="currentWorkspace" />
        <Form
          :form-item="activeFormItem"
          @next="handleNext" 
          @previous="handlePrevious" />
      </div>
    </div>
  </div>
</template>

<script>
import CarLoader from "@/components/ui/CarLoader.vue"
import coreApi from "@/providers/core-api"
import Form from "@/components/ui/Form.vue"
import Header from "@/components/home-checkin/navigation/Header.vue"
import StringService from "@/services/StringService.js"

export default {
  name: "HomeCheckin",
  components: {
    CarLoader,
    Form,
    Header
  },
  data() { 
    return {
      appointment: null,
      isLoading: false,
      isError: false,
      tabDirection: "next",
      activeItemId: null,
      services: [],
      formItems: [],
      isNextLoading: false,
      courtesy_vehicle: null
    }
  },
  created() {
    this.setData()
    document.title = "SimplyCheck - enregistrement en ligne"
  },
  computed: {
    activeFormItem() {
      if (this.itemIndex == null) {
        return null
      }

      let activeFormItem = this.formItems.find(item => item.id == this.activeItemId)
      activeFormItem.datas.index = this.itemIndex
      activeFormItem.datas.isLoading = this.isNextLoading
      
      return activeFormItem
    },
    logo() {
      let workspaceLogo = this.currentWorkspace.theme.picture_url || null
      return workspaceLogo ? workspaceLogo : this.simplyCheckLogo
    },
    currentWorkspace() {
      return this.$store.state.workspaceCheckin.workspace
    },
    itemIndex() {
      if (this.activeItemId) {
        return this.formItems.findIndex(item => item.id == this.activeItemId)
      }
      return null
    },
    customerEmail() {
      return this.formItems.find(item => item.id == 6).datas.value || null
    }
  },
  methods: {
    startCheckin() {
      if (!this.appointment.checkin || !this.appointment.checkin.home_checkin_started_at) {
        return coreApi.appointments.createAppointment(this.appointment.id, { type: "home_checkin" })
          .then(checkin => {
            this.$store.commit("workspaceCheckin/setCheckin", checkin)
            this.appointment.checkin = checkin
          })
      }
    },
    endCheckin() {
      let payload = {
        type: "home_checkin",
        completed: true,
        customer: {}
      }

      let customerDatas = ["firstname", "lastname", "email", "phone_number", ]

      this.formItems.forEach(item => {
        if (item.datas.value_label && item.datas.value) {
          if (customerDatas.includes(item.datas.value_label)) {
            payload["customer"][item.datas.value_label] = this.getItemValue(item)
            return
          }
          payload[item.datas.value_label] = this.getItemValue(item)
        }
      })

      if (payload.checkin) {
        this.isLoading = true

        return coreApi.checkins.updateCheckin(this.$store.state.workspaceCheckin.checkin.id, payload)
          .then(() => {
            this.$router.push({ 
              name: "workspace-checkin", 
              query: {
                "plate_number": this.appointment.vehicle.plate_number || null,
                "mileage": this.appointment.vehicle.mileage || null
              },
              params: { "workspaceId": this.appointment.workspace.id }
            })
          })
      }
      return coreApi.checkins.updateCheckin(this.$store.state.workspaceCheckin.checkin.id, payload)
    },
    getItemValue(item) {
      if (item.type == "licence_picture") {
        return item.datas.value.id
      }

      if (item.type == "services") {
        return item.datas.value.map(service => {
          return {
            action: service.action,
            type: service.type,
            value: service.id
          }
        })
      }

      return item.datas.value
    },
    setData() {
      this.isLoading = true

      let appointmentId = this.$route.params.appointmentId
      coreApi.appointments.getAppointment(appointmentId)
        .then(appointment => {
          this.appointment = appointment
          this.$store.commit("workspaceCheckin/setWorkspace", appointment.workspace)
          this.$store.commit("workspaceCheckin/setCheckin", appointment.checkin)
          this.getServices(appointment.workspace.id, appointmentId)
          this.getLoanerContract()
        })
        .catch((error) => {
          window.console.error(error)
          this.isError = true
        })
    },
    getLoanerContract() {
      let appointmentId = this.$route.params.appointmentId

      coreApi.appointments.getLoanerContract(appointmentId)
        .then((contract) => {
          if (contract && contract.length) {
            this.courtesy_vehicle = contract[0]
          }
        })
    },
    getServices(workspaceId, appointmentId) {
      coreApi.workshops.getWorkshopServices(workspaceId, {
        active: true,
        appointment_id: appointmentId
      })
        .then(services => {
          this.services = services
        })
        .finally(() => {
          this.isLoading = false
          this.setFormItems()
          this.activeItemId = this.formItems[0].id
        })
    },
    handleNext(value=null) {
      let promises = []

      let activeItem = this.formItems.find(item => item.id == this.activeItemId)
      activeItem.datas.value = value 

      if (this.activeFormItem.datas.handler) {
        promises.push(this.activeFormItem.datas.handler())
      }

      this.isNextLoading = true

      Promise.all(promises)
        .then(() => {
          if (this.formItems[this.itemIndex + 1]) {
            this.activeItemId = this.formItems[this.itemIndex + 1].id
          }
        })
        .finally(() => {
          this.isNextLoading = false
        })
    },
    handlePrevious() {
      if (this.formItems[this.itemIndex - 1]) {
        this.activeItemId = this.formItems[this.itemIndex - 1].id
      }
    },
    setFormItems() {
      let checkin = this.appointment.checkin || null
      this.formItems = []

      let canStart = !checkin || (!checkin.home_checkin_completed_at && !checkin.customer_inspection_started_at && !checkin.receptionist_inspection_started_at)

      if (!canStart) {
        this.formItems.push(
          {
            id: 2,
            type: "text_bubble",
            datas: { 
              validators: [],
              text: `Bonjour. 👋 Je suis l’assistante virtuelle de Nathalie, membre de l’équipe ${this.currentWorkspace.commercial_name}.\nIl n'est plus possible de réaliser votre enregistrement en ligne pour ce rendez-vous.\nJe reste joignable au ${StringService.formatPhoneNumber(this.currentWorkspace.phone_number)} pendant les horaires d’ouverture de votre point de vente pour toute question.`,
              handler: this.startCheckin,
              actions: { display: "hidden" }
            }
          }
        )
        return
      }

      this.formItems.push(
        {
          id: 3,
          type: "text_bubble",
          datas: { 
            validators: [],
            text: `Bonjour. 👋 Je suis l’assistante virtuelle de Nathalie, membre de l’équipe ${this.currentWorkspace.commercial_name}.\nJe vais vous aider à préparer votre rendez-vous après vente en quelques clics.\nJe reste joignable au ${StringService.formatPhoneNumber(this.currentWorkspace.phone_number)} pendant les horaires d’ouverture de votre point de vente pour toute question.`,
            handler: this.startCheckin,
            actions: {
              description: "3 minutes 30 secondes",
              icon: "clock",
              type: "start"
            }
          }
        }
      )

      if (this.appointment.customer.business_name) {
        this.formItems.push({
          id: 4,
          type: "business_name",
          datas: {
            validators: ["string", "required"],
            placeholder: "Raison sociale",
            value_label: "business_name",
            value: this.appointment.customer.business_name || null,
            label: "Votre raison sociale est-il correctement renseigné ?",
            description: "Cette information est utile pour personnaliser votre expérience de réception."
          }
        })
      }
      
      this.formItems.push(
        {
          id: 5,
          type: "lastname",
          datas: {
            validators: ["string", "name", "required"],
            placeholder: "Nom",
            value_label: "lastname",
            value: this.appointment.customer.lastname || null,
            label: "Votre nom est-il correctement renseigné ?",
            description: "Cette information est utile pour personnaliser votre expérience de réception."
          }
        },
        {
          id: 55,
          type: "firstname",
          datas: {
            validators: ["string", "name", "required"],
            placeholder: "Prénom",
            value_label: "firstname",
            value: this.appointment.customer.firstname || null,
            label: "Votre prénom est-il correctement renseigné ?",
            description: "Cette information est utile pour personnaliser votre expérience de réception."
          }
        },
        {
          id: 6,
          type: "input",
          datas: {
            validators: ["string", "email", "required"],
            placeholder: "Email",
            value_label: "email",
            value: this.appointment.customer.email || null,
            label: "Votre adresse email est-elle correctement renseignée ?",
            description: "Cette information est utile pour personnaliser votre expérience de réception."
          }
        },
        {
          id: 7,
          type: "phone",
          datas: {
            validators: ["string", "phone", "required"],
            placeholder: "Numéro de téléphone",
            value_label: "phone_number",
            value: this.appointment.customer.phone_number || null,
            label: "Votre numéro de téléphone est-il correctement renseigné ?",
            description: "Cette information est utile pour personnaliser votre expérience de réception."
          }
        })

      if (this.services.length) {
        this.formItems.push(
          {
            id: 75,
            type: "services",
            datas: {
              validators: [],
              value_label: "services",
              services: this.services,
              value: [],
              label: "Avez-vous pensé à tout ?",
              description: "Recommandation réalisée à partir du croisement de votre historique d’entretien et du plan d’entretien constructeur."
            }
          },
        )}

      if (this.courtesy_vehicle) {
        this.formItems.push(
          {
            id: 35,
            type: "courtesy_vehicle",
            datas: {
              validators: [],
              value: this.courtesy_vehicle,
              label: "Votre réservation d’un véhicule de courtoisie : informations utiles",
              description: "Recommandation : reprenez les informations suivantes et transmettez-les à votre assureur pour opérer un transfert d’assurance et ainsi réduire la franchise applicable."
            }
          },
          {
            id: 81,
            type: "licence_number",
            datas: {
              validators: ["string", "licence", "required"],
              placeholder: "Numéro de permis",
              value_label: "licence_number",
              value: this.courtesy_vehicle.licence_number || null,
              label: "Veuillez renseigner votre numéro de permis",
              description: "Cette preuve est indispensable pour récupérer votre véhicule de courtoisie."
            }
          },
          {
            id: 815,
            type: "date",
            datas: {
              validators: ["date", "required"],
              value_label: "licence_date",
              value: this.courtesy_vehicle.licence_date || null,
              label: "Veuillez renseigner la date d’obtention de votre permis",
              description: "Cette preuve est indispensable pour récupérer votre véhicule de courtoisie."
            }
          },
          {
            id: 82,
            type: "licence_picture",
            datas: {
              validators: [],
              value_label: "licence_front",
              value: null,
              label: "Merci de procéder à la prise en photo du recto de votre permis de conduire",
              description: "Cette photographie est facultative pour la récupération votre véhicule de courtoisie."
            }
          },
          {
            id: 83,
            type: "licence_picture",
            datas: {
              validators: [],
              value_label: "licence_back",
              value: null,
              label: "Merci de procéder à la prise en photo du verso de votre permis de conduire",
              description: "Cette photographie est facultative pour la récupération votre véhicule de courtoisie."
            }
          }
        )
      }

      this.formItems.push(
        {
          id: 11,
          type: "radio_list",
          datas: {
            validators: ["required"],
            value_label: "checkin",
            label: "Souhaitez-vous réaliser l’état des lieux de votre véhicule vous même depuis votre domicile ?",
            handler: this.endCheckin,
            description: "Pour un gain de temps en point de vente.",
            actions: { type: "finish" },
            possibilities: [{
              label: "Oui",
              value: true
            }, {
              label: "Non",
              value: false
            }],
            value: []
          }
        },
        {
          id: 12,
          type: "conclusion",
          datas: { 
            validators: [],
            text: `Vous venez de terminer l’enregistrement en ligne. Merci. Les informations ont été transmises à l’équipe ${this.currentWorkspace.commercial_name}.\nUn email contenant une confirmation d’enregistrement en ligne et le formulaire d’état des lieux de véhicule à domicile vient d’être envoyé à l’adresse ${this.customerEmail}.`,
            actions: { type: "hidden" }
          }
        }
      )
    }
  } 
}
</script>