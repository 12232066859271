<template>
  <Page>
    <WorkspaceLogo :workspace="workspace" />
    <p class="max-w-sm">
      Bonjour.<br>
      Bienvenue dans nos locaux.<br>
      Créez la preuve de l’état de votre véhicule avant travaux et <strong>gagnez du temps</strong>.
    </p>
    <div class="flex flex-col gap-2">
      <Input
        label="Immatriculation"
        icon="car-side"
        :placeholders="['AA 123 BB', '1234 AA 54']"
        :mask="plateNumberMask"
        @maska="setUnmasked($event, 'plateNumber')"
        :validations="isValid('plateNumber')"
        v-model="input.plateNumber" />
      <Input
        label="Kilomètrage"
        icon="counter"
        suffix="km"
        :mask="numberMask"
        placeholder="25 000"
        @maska="setUnmasked($event, 'mileage')"
        :validations="isValid('mileage')"
        v-model="input.mileage"/>
    </div>
    <template v-slot:footer>
      <Footer
        :error-message="errorMessage"
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        @next="handleNext"
        @previous="handlePrevious"
        :navigations="navigations" />
    </template>
  </Page>
</template>

<script>
import coreApi from "@/providers/core-api"
import Footer from "@/components/workspace-checkin/navigation/Footer.vue"
import Input from "@/components/ui/Input.vue"
import Page from "@/components/workspace-checkin/navigation/Page.vue"
import useMask from "@/composables/useMask.js"
import WorkspaceLogo from "@/components/workspace-checkin/ui/WorkspaceLogo.vue"

export default {
  name: "CustomerInformation",
  components: {
    Footer,
    Input,
    Page,
    WorkspaceLogo
  },
  props: {
    navigations: {
      type: Object,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    }
  },
  emits: ["next", "previous"],
  data() {
    return {
      validations: null,
      isLoading: false,
      errorMessage: null,
      input: {
        plateNumber: null,
        mileage: null,
      },
      form: {
        plateNumber: null,
        mileage: null,
      },
      rules: {
        plateNumber: {
          presence: { message: "^La plaque d'immatriculation doit être renseignée." },
          format: {
            pattern: /^([A-Z]{2}[0-9]{3}[A-Z]{2}|[0-9]{1,4}[A-Z]{1,3}[0-9]{2})$/,
            message: "^Le format de la plaque n'est pas valide."
          },
        },
        mileage: {
          presence: { message: "^Le kilomètrage doit être renseigné." },
          numericality: {
            onlyInteger: true,
            greaterThan: 0,
            message: "^Le kilomètrage doit être supérieur à 0."
          },
        },
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.form.plateNumber && this.form.mileage) {
        return false
      }
      return true
    },
    appointmentId() {
      return this.$store.state.workspaceCheckin.appointmentId
    }
  },
  setup() {
    const plateNumberMask = useMask("plate_number")
    const numberMask = useMask("number")

    return {
      plateNumberMask,
      numberMask 
    }
  },
  created() {
    this.form.plateNumber = this.$store.state.workspaceCheckin.plateNumber || this.$route.query.plate_number
    this.input.plateNumber = this.form.plateNumber
    this.form.mileage = this.$store.state.workspaceCheckin.mileage || this.$route.query.mileage
    this.input.mileage = this.form.mileage
    if (!this.$store.state.workspaceCheckin.positions || !this.$store.state.workspaceCheckin.positions.length) {
      this.isLoading = true
      this.$store.dispatch("workspaceCheckin/loadCheckinPositions")
        .then(() => {
          this.$store.dispatch("workspaceCheckin/loadPictures")
            .finally(() => {this.isLoading = false})
        })
    }
  },
  methods: {
    handleNext() {
      this.validations = this.$validate(this.form, this.rules)
      if (this.validations) {
        return
      }

      let plateNumber = this.$store.state.workspaceCheckin.plateNumber
      this.isLoading = true

      if (this.appointmentId && (plateNumber == this.form.plateNumber)) {
        this.updateAppointmentMileage()
        return
      }

      if (plateNumber != this.form.plateNumber) {
        this.$store.commit("workspaceCheckin/setPlateNumber", this.form.plateNumber)
      }

      this.getAppointmentsId()
        .then((appointment) => {
          if (appointment.checkin && appointment.checkin.workspace_checkin_started_at) {
            this.updateAppointmentMileage()
            return
          }
          this.updateAppointment() 
        })
        .catch((error) => {
          window.console.error(error)
          this.isLoading = false
        })
    },
    handlePrevious() {
      if (this.navigations.previous) {
        this.$emit("previous")
      }
    },
    setAppointmentId(appointment) {
      this.errorMessage = null
      if (appointment.length > 0) {
        this.$store.commit("workspaceCheckin/setAppointmentId", appointment[0].id)
        this.$store.commit("workspaceCheckin/setCheckin", appointment[0].checkin)
        return appointment[0]
      }
      this.errorMessage = "Aucun rendez-vous n'a été trouvé pour cette immatriculation."
      return Promise.reject()
    },
    updateAppointment() {
      let payload = { type: this.$store.state.workspaceCheckin.isReceptionist ? "receptionist_inspection" : "customer_inspection" }
      coreApi.appointments.createAppointment(this.appointmentId, payload)
        .then((response) => {
          this.$store.commit("workspaceCheckin/setCheckin", response)
          this.updateAppointmentMileage()
        })
        .catch((error) => {
          if (error.status === 409) {
            this.errorMessage = "Il n'est plus possible de réaliser l’état des lieux pour ce rendez-vous."
            this.isLoading = false
          } 
        })
    },
    updateAppointmentMileage() {
      let payload = { mileage: this.form.mileage.replace(" ", "") }

      coreApi.checkins.updateCheckin(this.$store.state.workspaceCheckin.checkin.id, payload)
        .then((checkin) => {
          this.$store.commit("workspaceCheckin/setCheckin", checkin)
          // check if customer information has been already visited
          if (!this.$store.state.workspaceCheckin.mileage) {
            this.setPictures(checkin.pictures)
          }
          this.$emit("next")
        })
        .catch((error) => {
          if (error.status === 409) {
            this.errorMessage = "Il n'est plus possible de réaliser l’état des lieux pour ce rendez-vous."
          }
        })
        .finally(() => {
          this.$store.commit("workspaceCheckin/setMileage", this.form.mileage)
          this.isLoading = false
        })
    },
    setPictures(pictures) {
      if (pictures && pictures.length) {
        pictures.forEach(picture => {
          if (picture.type == "main") {
            this.$store.commit("workspaceCheckin/updatePictureUrl", {
              position: picture.position,
              url: picture.url,
              id: picture.id
            })
            return
          }

          if (picture.type == "defect") {
            this.$store.commit("workspaceCheckin/addDefect", {
              position: picture.position,
              pictureUrl: picture.url,
              id: picture.id
            })
          }
        })
      }
    },
    getAppointmentsId() {
      let start_date = this.$moment().startOf("day").format("YYYY-MM-DD HH:mm")
      let end_date = this.$moment().add(10 ,"days").endOf("day").format("YYYY-MM-DD HH:mm")

      let payload = {
        plate_number: this.form.plateNumber,
        start_date: start_date,
        end_date: end_date
      }

      return coreApi.workspaces.getPlateAppointments(this.workspace.id, payload)
        .then(this.setAppointmentId)
    },
    isValid(type) {
      return this.validations ? this.validations[type] : null
    },
    setUnmasked(value, type) {
      this.form[type] = value.detail.unmasked
    }
  },
}
</script>
