<template>
  <div class="w-full flex justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 958 567"
      class="max-h-[300px]"
      preserveAspectRatio="xMidYMid meet"
      style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g
        :class="getClass('front_right_tire')"
        @click="goTo('front_right_tire')"><path
          stroke-width="4"
          d="M 259.5,42.5 C 288.734,39.9262 307.567,52.5928 316,80.5C 319.06,109.756 306.56,128.59 278.5,137C 249.244,140.06 230.41,127.56 222,99.5C 219.146,70.2193 231.646,51.2193 259.5,42.5 Z"/></g>
      <g
        :class="getClass('back_right_tire')"
        @click="goTo('back_right_tire')"><path
          stroke-width="4"
          d="M 693.5,43.5 C 722.734,40.9262 741.567,53.5928 750,81.5C 753.06,110.756 740.56,129.59 712.5,138C 683.244,141.06 664.41,128.56 656,100.5C 653.146,71.2193 665.646,52.2193 693.5,43.5 Z"/></g>
      <g
        :class="getClass('front')"
        @click="goTo('front')"><path
          stroke-width="4"
          d="M 76.5,79.5 C 111.502,79.3333 146.502,79.5 181.5,80C 193.443,81.2756 202.943,86.7756 210,96.5C 227.208,138.796 257.374,152.296 300.5,137C 305.11,133.448 309.944,130.281 315,127.5C 318.333,128.167 320.333,130.167 321,133.5C 321.167,145.181 321.667,156.848 322.5,168.5C 343.5,180.667 364.5,192.833 385.5,205C 386.667,206.167 387.833,207.333 389,208.5C 389.667,254.5 389.667,300.5 389,346.5C 387.833,347.667 386.667,348.833 385.5,350C 364.5,362.167 343.5,374.333 322.5,386.5C 321.667,398.152 321.167,409.819 321,421.5C 320.333,424.833 318.333,426.833 315,427.5C 293.654,410.255 270.154,406.755 244.5,417C 227.619,426.226 216.119,440.06 210,458.5C 204.888,465.289 198.388,470.123 190.5,473C 170.882,474.143 151.216,474.643 131.5,474.5C 112.928,474.497 94.428,474.164 76,473.5C 75.1686,342.097 75.3353,210.764 76.5,79.5 Z"/></g>
      <g
        :class="getClass('right')"
        @click="goTo('right')"><path
          stroke-width="4"
          d="M 354.5,94.5 C 445.834,94.3333 537.167,94.5 628.5,95C 635.528,96.9219 640.694,101.089 644,107.5C 644.667,123.5 644.667,139.5 644,155.5C 622.183,171.839 599.683,187.339 576.5,202C 519.833,202.667 463.167,202.667 406.5,202C 383.317,187.339 360.817,171.839 339,155.5C 338.333,139.5 338.333,123.5 339,107.5C 342.542,101.157 347.709,96.8236 354.5,94.5 Z"/></g>
      <g
        :class="getClass('back')"
        @click="goTo('back')"><path
          stroke-width="4"
          d="M 777.5,95.5 C 808.74,95.171 839.907,95.5044 871,96.5C 871.832,218.736 871.665,340.903 870.5,463C 839.5,463.667 808.5,463.667 777.5,463C 773.888,461.861 770.554,460.194 767.5,458C 759.279,447.446 751.446,436.613 744,425.5C 727.633,410.368 708.8,406.201 687.5,413C 682.669,414.61 678.003,416.61 673.5,419C 671.299,419.825 669.466,419.325 668,417.5C 667.829,412.136 667.329,406.802 666.5,401.5C 638.074,382.871 609.908,363.871 582,344.5C 581.333,301.167 581.333,257.833 582,214.5C 609.908,195.129 638.074,176.129 666.5,157.5C 667.329,152.198 667.829,146.864 668,141.5C 669.466,139.675 671.299,139.175 673.5,140C 690.893,149.527 708.893,151.194 727.5,145C 732.772,142.059 737.772,138.726 742.5,135C 750.368,124.764 757.868,114.264 765,103.5C 768.671,99.9227 772.838,97.2561 777.5,95.5 Z"/></g>
      <g
        :class="getClass('engine_bay')"
        @click="goTo('engine_bay')"><path
          stroke-width="4"
          d="M 282.5,174.5 C 286.57,173.508 288.57,175.008 288.5,179C 285.481,191.315 283.648,203.815 283,216.5C 282.333,255.167 282.333,293.833 283,332.5C 284.098,348.941 285.765,365.275 288,381.5C 287.107,382.081 286.107,382.415 285,382.5C 247.077,376.781 209.244,370.614 171.5,364C 165.175,361.201 161.341,356.368 160,349.5C 159.333,301.833 159.333,254.167 160,206.5C 162.167,199 167,194.167 174.5,192C 210.663,186.318 246.663,180.485 282.5,174.5 Z"/></g>
      <g
        :class="getClass('front_number_plate')"
        @click="goTo('front_number_plate')"><path
          stroke-width="4"
          d="M 109.5,218.5 C 118.506,218.334 127.506,218.5 136.5,219C 138.333,220.167 139.833,221.667 141,223.5C 141.667,259.167 141.667,294.833 141,330.5C 139.833,332.333 138.333,333.833 136.5,335C 127.5,335.667 118.5,335.667 109.5,335C 107.667,333.833 106.167,332.333 105,330.5C 104.333,294.833 104.333,259.167 105,223.5C 106.366,221.638 107.866,219.972 109.5,218.5 Z"/></g>
      <g
        :class="getClass('mileage_reader')"
        @click="goTo('mileage_reader')"><path
          stroke-width="4"
          d="M 425.5,235.5 C 429.437,235.207 433.27,235.54 437,236.5C 437.667,272.5 437.667,308.5 437,344.5C 426.572,346.797 418.239,343.464 412,334.5C 401.453,316.56 397.786,297.227 401,276.5C 402.518,261.631 408.685,249.131 419.5,239C 421.727,238.059 423.727,236.892 425.5,235.5 Z"/></g>
      <g
        :class="getClass('left')"
        @click="goTo('left')"><path
          stroke-width="4"
          d="M 406.5,350.5 C 463.168,350.333 519.834,350.5 576.5,351C 598.753,365.709 620.919,380.543 643,395.5C 644.637,412.075 644.97,428.742 644,445.5C 640.694,451.911 635.528,456.078 628.5,458C 536.11,458.992 443.777,458.659 351.5,457C 345.728,454.894 341.562,451.061 339,445.5C 338.03,428.742 338.363,412.075 340,395.5C 362.319,380.601 384.486,365.601 406.5,350.5 Z"/></g>
      <g
        :class="getClass('front_left_tire')"
        @click="goTo('front_left_tire')"><path
          stroke-width="4"
          d="M 259.5,418.5 C 288.734,415.926 307.567,428.593 316,456.5C 319.06,485.756 306.56,504.59 278.5,513C 249.244,516.06 230.41,503.56 222,475.5C 219.146,446.219 231.646,427.219 259.5,418.5 Z"/></g>
      <g
        :class="getClass('back_left_tire')"
        @click="goTo('back_left_tire')"><path
          stroke-width="4"
          d="M 693.5,419.5 C 722.734,416.926 741.567,429.593 750,457.5C 753.06,486.756 740.56,505.59 712.5,514C 683.244,517.06 664.41,504.56 656,476.5C 653.146,447.219 665.646,428.219 693.5,419.5 Z"/></g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CarParts",
  props: {
    defaultValid: {
      type: Boolean,
      default: false
    },
    valids: {
      type: Array,
      default: () => []
    },
    defects: {
      type: Array,
      default: () => []
    },
    positions: {
      type: Array,
      required: true
    },
    isClickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goTo(position) {
      if (this.positions.includes(position)) {
        this.$emit("picture", position)
      }
    },
    getClass(position) {
      let stroke = "stroke-gray01"
      let fill = "fill-plainWhite hover:fill-gray05"
      let additional = this.isClickable ? "cursor-pointer" : ""

      if (!this.positions.includes(position)) {
        stroke = "stroke-paperWhite"
        fill = `fill-gray05 ${this.isClickable ? "hover:fill-gray05" : ""}`
        additional = this.isClickable ? "cursor-not-allowed" : ""
        return [stroke, fill, position, additional].join(" ")
      }

      if (this.defects.includes(position)) {
        fill = `fill-mandarineOrange/20 ${this.isClickable ? "hover:fill-mandarineOrange/40" : ""}`
        stroke = "stroke-mandarineOrange/75"
        return [stroke, fill, position, additional].join(" ")
      }

      if (this.valids.includes(position) || this.defaultValid) {
        fill = `fill-grassGreen/20 ${this.isClickable ? "hover:fill-grassGreen/40" : ""}`
        stroke = "stroke-grassGreen/75"
        return [stroke, fill, position, additional].join(" ")
      }

      return [stroke, fill, position, additional].join(" ")
    }
  }
}
</script>