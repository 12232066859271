<template>
  <span v-if="isLoading">Chargement...</span>
  <div
    v-else
    class="relative h-full bg-zinc-600 flex justify-center">
    <div class="px-4 py-8 flex flex-col bg-plainWhite max-w-3xl w-full shadow-sm overflow-y-scroll">
      <Header :workspace="checkin.workspace" />
      <CustomerInformation :customer="checkin.customer" />
      <VehicleInformation :vehicle="checkin.vehicle" />
      <AppointmentInformation :appointment="checkin.appointment" />
      <ServicesAsked :services="checkin.services" />
      <div class="html2pdf__page-break"/>
      <PictureList
        :pictures="checkin.pictures"
        :positions="positions" />
      <Signature
        :pictures="checkin.pictures"
        :checkin="checkin"
        :date="checkin.workspace_checkin_completed_at" />
    </div>
  </div>
</template>

<script>
import AppointmentInformation from "@/components/summary/AppointmentInformation"
import coreApi from "@/providers/core-api"
import CustomerInformation from "@/components/summary/CustomerInformation"
import Header from "@/components/summary/Header.vue"
import PictureList from "@/components/summary/PictureList"
import ServicesAsked from "@/components/summary/ServicesAsked"
import Signature from "@/components/summary/Signature"
import VehicleInformation from "@/components/summary/VehicleInformation"

export default {
  name: "CheckinSummary",
  components: {
    AppointmentInformation,
    Header,
    PictureList,
    CustomerInformation,
    ServicesAsked,
    VehicleInformation,
    Signature
  },
  data() {
    return {
      checkin: null,
      isLoading: false
    }
  },
  created() {
    this.handleInit()
    document.title = "SimplyCheck - compte rendu"
  },
  computed: {
    options() {
      return {
        positions: [],
        checkin: {},
        isLoading: false
      }
    },
    currentCheckinId() {
      return this.$route.params.checkinId
    },
    summaryLabel() {
      let date = ""
      if (this.checkin) {
        date = this.$moment(this.checkin.workspace_checkin_completed_at).format("DD-MM-YYYY")
      }
      return `résumé-coupe-file-${date}`
    }
  },
  methods: {
    handleInit() {
      this.isLoading = true
      coreApi.checkins.getCheckin(this.currentCheckinId)
        .then(checkin => {
          this.checkin = checkin
          this.getPositions()
        })
    },
    getPositions() {
      coreApi.workspaces.getWorkspaceCheckinPositions(this.checkin.workspace.id)
        .then(positions => {
          this.positions = positions
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
