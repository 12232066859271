<template>
  <ServiceLayout
    :label="props.category.label"
    :subtitle="subtitle">
    <ServiceItem
      v-for="service in props.category.services"
      @update="handleUpdate"
      :service="service"
      :selected-services="selectedServices"
      :key="service.id"/>
  </ServiceLayout>
</template>

<script setup>
import {
  computed, defineEmits, defineProps, ref 
} from "vue"
import ServiceItem from "@/components/ui/form/services/ServiceItem"
import ServiceLayout from "@/components/ui/form/services/ServiceLayout"

const props = defineProps({
  category: {
    type: Object,
    required: true
  },
  selectedServices: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["update"])

const subtitle = computed(() => {
  let count = ref(0)
  let selectedServicesIds = ref(props.selectedServices.map(service => service.id)) 

  props.category.services.forEach(service => {
    if (selectedServicesIds.value.includes(service.id)) {
      count.value++
    }
  })

  return count.value ? `${count.value} prestation(s) demandé(es)` : null
})

function handleUpdate(event) {
  emit("update", event)
}
</script>