<template>
  <ListItem
    :label="service.label"
    @clicked="openSelection">
    <span v-if="service.price">
      À partir de <span class="font-medium text-gray02">{{ price }}</span>
    </span>
    <template
      v-slot:additional
      v-if="selectedOption">
      {{ $t(`services.${selectedOption}`) }}
    </template>
  </ListItem>
</template>

<script>
import ListItem from "@/components/ui/ListItem.vue"
import StringService from "@/services/StringService.js"

export default {
  name: "ServiceItem",
  components: { ListItem },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    additionalServices() {
      return this.$store.state.workspaceCheckin.checkin.services
    },
    selectedOption() {
      const service = this.additionalServices.find(service => service.id === this.service.id)
      return service ? service.action : null
    },
    price() {
      let currency = this.$store.state.workspaceCheckin.workspace.country
      return StringService.currency(this.service.price, currency, false)
    }
  },
  methods: {
    openSelection() {
      this.$emit("open-selection", this.service)
    }
  }
}
</script>
