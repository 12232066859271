<template>
  <Page>
    <div v-if="isError">Erreur</div>
    <div
      v-if="isReceptionist"
      class="flex flex-col gap-4 w-full">
      <div v-if="currentSignature">
        <SignatureInput
          :label="currentSignature.label"
          @change="currentSignature.value = $event" />
      </div>
      <template v-else>
        <div
          class="flex justify-between items-center"
          v-for="signature in signatures"
          :key="signature.label">
          <span>{{ signature.label }} :</span>
          <img
            v-if="signature.value"
            @click="handleSign(signature)"
            class="h-32"
            :src="signature.value"
            alt="signature">
          <Button
            v-else
            @clicked="handleSign(signature)"
            label="Signer" />
        </div>
      </template>
    </div>
    <SignatureInput
      v-else
      @change="currentSignature.value = $event"/>
    <template v-slot:footer>
      <Footer
        :is-loading="isLoading"
        :is-disabled="isDisabled"
        @previous="handlePrevious"
        @close="handleClose"
        @finish="handleFinish"
        :navigations="currentNavigations" />
    </template>
  </Page>
</template>

<script>
import Button from "@/components/ui/Button"
import coreApi from "@/providers/core-api"
import Footer from "@/components/workspace-checkin/navigation/Footer.vue"
import Page from "@/components/workspace-checkin/navigation/Page.vue"
import SignatureInput from "@/components/workspace-checkin/pages/signature/SignatureInput.vue"

export default {
  name: "Signature",
  components: {
    Button,
    Footer,
    Page,
    SignatureInput
  },
  props: {
    navigations: {
      type: Object,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      currentSignature: null,
      signatures: {
        customer: {
          label: "Signature client",
          valueLabel: "customer",
          value: null
        },
        receptionist: {
          label: "Signature conseiller client*",
          valueLabel: "receptionist",
          value: null
        }
      }
    }
  },
  computed: {
    currentCheckinId() {
      return this.$store.state.workspaceCheckin.checkin.id
    },
    isReceptionist() {
      return this.$store.state.workspaceCheckin.isReceptionist
    },
    currentNavigations() {
      if (this.isReceptionist && this.currentSignature) {
        return {
          close: true,
          finish: true
        }
      }
      return this.navigations
    },
    isDisabled() {
      if (!this.isReceptionist) {
        return !this.signatures.customer.value
      }

      if (!this.currentSignature) {
        return !this.signatures.receptionist.value
      }

      return !this.currentSignature.value
    },
  },
  created() {
    if (!this.isReceptionist) {
      this.currentSignature = this.signatures.customer
    }

    this.setSignatures()
  },
  methods: {
    handlePrevious() {
      if (this.navigations.previous) {
        this.$emit("previous")
      }
    },
    setSignatures() {
      let pictures = this.$store.state.workspaceCheckin.checkin.pictures

      if (pictures.findIndex(p => p.type == "customer_signature") !== -1) {
        this.signatures.customer.value = pictures.find(p => p.type == "customer_signature").url
      }

      if (pictures.findIndex(p => p.type == "receptionist_signature") !== -1) {
        this.signatures.receptionist.value = pictures.find(p => p.type == "receptionist_signature").url
      }
    },
    handleSign(signature) {
      this.currentSignature = signature
    },
    handleClose() {
      this.currentSignature.value = null
      this.currentSignature = null
    },
    handleFinish() {
      if (this.isReceptionist && this.currentSignature) {
        this.currentSignature = null
        return
      }

      this.isLoading = true

      let payload = {}

      payload.services = this.$store.state.workspaceCheckin.checkin.services.map(service => {
        return {
          value: service.id,
          type: service.type,
          action: service.action
        }
      })

      coreApi.checkins.updateCheckin(this.currentCheckinId, payload)
        .then(() => {
          this.uploadSignatures()
            .then(() => {
              let payload = {
                type: this.$store.state.workspaceCheckin.isReceptionist ? "receptionist_inspection" : "customer_inspection",
                completed: true
              }

              coreApi.checkins.updateCheckin(this.$store.state.workspaceCheckin.checkin.id, payload)
                .then(() => {
                  this.$emit("finish")
                })
                .finally(() => {
                  this.isLoading = false
                })
            })
        })
    },
    uploadSignatures() {
      let promises = []

      Object.keys(this.signatures).forEach(signature => {
        if (this.signatures[signature].value) {
          promises.push(this.uploadPicture(this.signatures[signature].value, this.signatures[signature].valueLabel))
        }
      })

      return Promise.all(promises)
    },
    handleReset() {
      this.$refs.signature.reset()
    },
    uploadPicture(value, label) {
      fetch(value)
        .then(signature => {
          return signature.blob()
        })
        .then(blob => {
          let payload = new FormData()
          payload.append("picture", blob, "picture.png")
          payload.append("type", label + "_signature")
          return coreApi.pictures.uploadPicture(this.currentCheckinId, payload)
        })
    }
  }
}
</script>
