class StringService {
  static currency(value, country, displayDecimals=true) {
    const CURRENCY_MAP = {
      "FR": {
        format: "fr-FR",
        currency: "EUR"
      },
      "CH": {
        format: "de-CH",
        currency: "CHF"
      }
    }
    
    let country_currency = CURRENCY_MAP[country] || CURRENCY_MAP["FR"] 
    const formatter = new Intl.NumberFormat(country_currency.format, {
      style: "currency",
      currency: country_currency.currency,
      maximumFractionDigits: displayDecimals ? 2 : 0
    })

    return formatter.format(value)
  }

  static title(value) {
    if (!value) {
      return ""
    }

    value = (value + "").toLowerCase()
    value = value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")

    return value
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  static uppercase(value) {
    if (value instanceof Array) {
      return value.map(e => e.toUpperCase())
    }
    return value ? value.toUpperCase() : ""
  }

  static ucfirst(value) {
    if (!value) {
      return ""
    }

    value = value + ""
    return value[0].toUpperCase() + value.substring(1)
  }

  static formatPlate(value, char=" ") {
    return value ? value.replace(/\s/g, "").toUpperCase().match(/\d+|\D+/g).join(char) : ""
  }

  static formatPhoneNumber(value, displayDiallingCode = true) {
    if (!value) {
      return ""
    }

    let diallingCode = null
    value = (value + "").replace(/\s/g, "")
    if (value.length > 10) {
      diallingCode = value.substring(0, 3)
      value = "0" + value.substring(value.length - 9, value.length)
    }

    return this.formatCountryPhoneNumber(value, diallingCode, displayDiallingCode)
  }

  static formatCountryPhoneNumber(localPhoneNumber, diallingCode, displayDiallingCode = true) {
    const FORMAT_HANDLERS = {
      "+33": this._formatFrenchPhoneNumber,
      "+41": this._formatSwissPhoneNumber
    }
    const handler = FORMAT_HANDLERS[diallingCode] || this._formatFrenchPhoneNumber
    return handler(localPhoneNumber, displayDiallingCode ? diallingCode : null)
  }

  static _formatFrenchPhoneNumber(phoneNumber, diallingCode) {
    if (!diallingCode) {
      return phoneNumber.replace(/([0-9]{2})/g, "$1 ").trim()
    }

    return (
      diallingCode + " " + phoneNumber.substring(1, 2) + " " +
      phoneNumber.substring(2, 10).replace(/([0-9]{2})/g, "$1 ").trim())
  }

  static _formatSwissPhoneNumber(phoneNumber, diallingCode) {
    if (!diallingCode) {
      return (
        phoneNumber.substring(0, 3) + " " + phoneNumber.substring(3, 6) + " " +
        phoneNumber.substring(6, 8) + " " + phoneNumber.substring(8, 10))
    }

    return (
      diallingCode + " " +
      phoneNumber.substring(1, 3) + " " + phoneNumber.substring(3, 6) + " " +
      phoneNumber.substring(6, 8) + " " + phoneNumber.substring(8, 10))
  }
}

export default StringService