<template>
  <div
    class="absolute w-full h-full bg-plainWhite z-10 flex flex-col items-start justify-between "
    :class="{'hidden': !isOpen}">
    <div class="flex flex-col items-start gap-4 w-full">
      <div class="flex justify-between w-full items-center">
        <div class="flex flex-col">
          <span class="font-bold">{{ service.label }}</span>
          <span
            v-if="service.price"
            class="flex-1 text-sm text-gray03">À partir de <span class="font-medium text-gray02">{{ price }}</span></span>
        </div>
        <close
          class="w-5"
          @click="isOpen = false"/>
      </div>
      <div class="flex flex-col gap-1 first:rounded-t-lg last:rounded-b-lg overflow-hidden w-full">
        <div
          class="relative h-12 px-4 w-full  flex justify-between items-center bg-paperWhite"
          v-for="(value, key) in options"
          :key="key"
          @click="setServiceAction(key)">
          <span class="flex-1 text-sm font-medium truncate">{{value}}</span>
          <check
            class="text-mandarineOrange w-[24px]"
            v-if="selectedOption == key" />
        </div>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <Button
        label="Enregistrer"
        type="dark"
        @click="handleClose" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button"
import StringService from "@/services/StringService.js"

export default {
  name: "ServiceSelection",
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  components: { Button },
  data() {
    return {
      isOpen: false,
      options: {
        unactive: "Non",
        replacement: this.$t("services.replacement"),
        inspection: this.$t("services.inspection")
      }
    }
  },
  computed: {
    additionalServices() {
      return this.$store.state.workspaceCheckin.checkin.services
    },
    selectedOption() {
      const service = this.additionalServices.find(service => service.id === this.service.id)
      return service ? service.action : "unactive"
    },
    price() {
      let currency = this.$store.state.workspaceCheckin.workspace.country
      return StringService.currency(this.service.price, currency, false)
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    handleClose() {
      this.isOpen = false
    },
    setServiceAction(action) {
      if (action == this.selectedOption) {
        return
      }

      if (action == "unactive") {
        this.handleUnselect()
        this.handleClose()
        return
      }

      this.handleSelect(action)
      this.handleClose()
    },
    handleSelect(option) {
      let additionalServices = JSON.parse(JSON.stringify(this.additionalServices))

      let service = additionalServices.find(service => service.id === this.service.id)
      if (!service) {
        service = {
          id: this.service.id,
          type: this.service.type 
        }
        additionalServices.push(service)
      }

      service.action = option
      this.$store.commit("workspaceCheckin/setCheckinServices", additionalServices)
    },
    handleUnselect() {
      let additionalServices = JSON.parse(JSON.stringify(this.additionalServices))

      additionalServices = additionalServices.filter((services) => { return services.id !== this.service.id })
      this.$store.commit("workspaceCheckin/setCheckinServices", additionalServices)
    }
  }
}
</script>
