<template>
  <div class="flex-1 flex items-center justify-center overflow-hidden relative">
    <template v-if="activeComponent">
      <transition
        :enter-from-class="`slide-${tabDirection}-enter`"
        :leave-to-class="`slide-${tabDirection}-leave`"
        name="slide">
        <component
          :is="activeComponent"
          :key="formItem.id"
          :item="formItem.datas"
          @previous="handlePrevious"
          @next="handleNext" />
      </transition>
    </template>
    <span v-else>Erreur</span>
  </div>
</template>

<script setup>
import {
  computed, defineEmits, defineProps, ref, shallowRef 
} from "vue"
import BusinessNameInput from "@/components/ui/form/inputs/BusinessNameInput"
import CheckboxList from "@/components/ui/form/CheckboxList"
import Conclusion from "@/components/ui/form/Conclusion"
import CourtesyVehicle from "@/components/ui/form/CourtesyVehicle"
import DateInput from "@/components/ui/form/inputs/DateInput"
import FirstnameInput from "@/components/ui/form/inputs/FirstnameInput"
import Input from "@/components/ui/form/Input"
import LastnameInput from "@/components/ui/form/inputs/LastnameInput"
import LicenceNumberInput from "@/components/ui/form/inputs/LicenceNumberInput"
import LicencePicture from "@/components/ui/form/LicencePicture"
import PhoneInput from "@/components/ui/form/inputs/PhoneInput"
import Picture from "@/components/ui/form/Picture"
import RadioList from "@/components/ui/form/RadioList"
import Services from "@/components/ui/form/Services"
import TextBubble from "@/components/ui/form/TextBubble"

const emit = defineEmits(["next", "previous"])

const props = defineProps({
  formItem: {
    type: Object,
    required: true
  },
})

const FORM_COMPONENTS_MAP = shallowRef({
  "input": Input,
  "radio_list": RadioList,
  "checkbox_list": CheckboxList,
  "text_bubble": TextBubble,
  "picture": Picture,
  "licence_picture": LicencePicture,
  "licence_number": LicenceNumberInput,
  "services": Services,
  "lastname": LastnameInput,
  "firstname": FirstnameInput,
  "business_name": BusinessNameInput,
  "phone": PhoneInput,
  "date": DateInput,
  "conclusion": Conclusion,
  "courtesy_vehicle": CourtesyVehicle
})

const activeComponent = computed(() => FORM_COMPONENTS_MAP.value[props.formItem.type] ? FORM_COMPONENTS_MAP.value[props.formItem.type] : null)
let tabDirection = ref("next")

function handleNext(value) {
  tabDirection.value = "next"
  emit("next", value)
}

function handlePrevious() {
  tabDirection.value = "previous"
  emit("previous")
}
</script>